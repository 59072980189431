import { Button } from 'reactstrap';
import { formatDate, formatDateTime } from 'utils/formatters';
import { getStatus } from 'utils/helpers';
import { inject } from 'mobx-react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTE } from 'definitions';
import { SHIPMENT_STATUS } from 'definitions';
import classNames from 'classnames';
import CreateReceivingModal from './CreateReceivingModal';
import qs from 'query-string';
import React from 'react';
import ReactTable from 'components/common/ReactTable';
import SearchForm from 'components/common/SearchForm';
import StatusFilter from 'components/common/StatusFilter';
import TableStatus from 'components/common/TableStatus';
import useToggleModal from 'utils/useToggleModal';

const ConsignmentShipment = (props) => {
  const {
    history,
    receivingStore: {
      Table: { data },
    },
    receivingStore,
    location,
    authStore,
  } = props;
  const currentWarehouse = window.location.pathname.split('/')[2];
  const { status = '' } = qs.parse(location.search);
  const [tableFilters, setTableFilters] = React.useState({
    status,
    pageIndex: 0,
    pageSize: 10,
  });
  const { isOpen, toggleModal } = useToggleModal({
    createReceivingModal: false,
  });
  const [isFullyLoaded, setIsFullyLoaded] = React.useState(false);

  React.useEffect(() => {
    setTableFilters((prevState) => ({ ...prevState, pageIndex: 0, status }));
  }, [status]);

  const fetchData = React.useCallback((options) => {
    setTableFilters((prevState) => ({ ...prevState, ...options }));
  }, []);

  const getData = React.useCallback(async () => {
    try {
      await receivingStore.search({
        page_num: tableFilters.pageIndex,
        page_size: tableFilters.pageSize,
        ...(status && { status }),
        ...(tableFilters.search_string && { search_string: tableFilters.search_string }),
      });
    } catch (err) {
      if (
        err.data.errors === 'Invalid path name' ||
        err.data.errors === 'You are not allowed to access this resource'
      ) {
        history.push('/notFound');
      }
    }
  }, [receivingStore, status, tableFilters.pageIndex, tableFilters.pageSize, tableFilters.search_string, history]);

  React.useEffect(() => {
    const fetchData = async () => {
      setIsFullyLoaded(false);
      if (currentWarehouse !== undefined && currentWarehouse !== '' && authStore.user.user_name != undefined) {
        await getData();
        setIsFullyLoaded(true);
      }
    };

    fetchData();
  }, [getData, currentWarehouse, authStore.user.user_name, history]);

  const onSearch = (value) => {
    setTableFilters((prevState) => ({ ...prevState, search_string: value, pageIndex: 0 }));
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Tracking No.	',
        accessor: 'waybill',
        Cell: ({ cell: { value } }) => value,
        width: 4,
      },
      {
        Header: 'Carrier',
        accessor: 'carrier',
      },
      {
        Header: 'Shipment Qty',
        className: 'text-center',
        Cell: ({ cell: { row } }) => row.original.lines.reduce((acc, value) => acc + value.quantity, 0),
        width: 4,
      },
      {
        Header: 'Received Qty',
        className: 'text-center',
        Cell: ({ cell: { row } }) => row.original.lines.reduce((acc, value) => acc + value.receivedQuantity, 0),
        width: 4,
      },
      {
        Header: 'Actual Ship Date',
        accessor: 'deliveryDate',
        className: 'text-center',
        Cell: ({ cell: { value } }) => {
          return <span>{formatDate(value)}</span>;
        },
        width: 8,
      },
      {
        Header: 'Status',
        accessor: 'status',
        className: 'text-center',
        width: 10,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <TableStatus type={getStatus(original).value} />,
      },
      {
        Header: 'Last Modified On',
        accessor: 'modifiedDate',
        className: 'text-center',
        width: 8,
        Cell: ({ cell: { value } }) => {
          return <span>{formatDateTime(value)}</span>;
        },
      },
    ],
    []
  );

  const filterButtons = [
    SHIPMENT_STATUS.ALL,
    SHIPMENT_STATUS.PENDING_RECEIVE,
    SHIPMENT_STATUS.PARTIAL_RECEIVED,
    SHIPMENT_STATUS.CLOSED,
    SHIPMENT_STATUS.COMPLETED,
  ];

  return (
    <>
      <div className="container-fluid p-md-5 p-3">
        <div className="breadcrumb">
          <h6>
            <Link to={`/inventory/${currentWarehouse}`}>Home</Link> {'>'} Consignment Shipment
          </h6>
        </div>
        <div className="head-wrapper">
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '-15px', marginBottom: '-15px' }}>
            <div className="head-title">
              <h4>
                <i className="material-icons md-dark md-36 pl-0">airplanemode_active</i>
                Consignment Shipment ({authStore.getWarehouseName(currentWarehouse)})
              </h4>
            </div>
            {authStore.isWarehouseAdmin(currentWarehouse) && (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button color="primary" onClick={() => toggleModal('createReceivingModal')}>
                  Create
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="search mt-4">
          <SearchForm onChange={onSearch} />
        </div>
        <div className="filter-common my-3">
          {filterButtons.map((i, key) => (
            <StatusFilter
              key={`status-filter-${key}`}
              state={status}
              type={i}
              pathname={`/${ROUTE.SHIPMENT}/${currentWarehouse}`}
            />
          ))}
        </div>
        <div className="table-inventory">
          <div id="count-records" className={classNames('mt-3 mb-4', { invisible: !isFullyLoaded })}>
            {`${data.total_count} Records Found`}
          </div>
          {/* <ReactTable
            data={data.items}
            columns={columns}
            options={{
              sortable: false,
              isLoading,
              fetchData,
              filters: tableFilters,
              pageCount: data.total_page,
              onRowClick: (row) => history.push(`/${ROUTE.SHIPMENT}/${row.original.id}`),
            }}
          /> */}

          <ReactTable
            data={data.items}
            columns={columns}
            options={{
              sortable: false,
              isLoading: !isFullyLoaded,
              fetchData,
              filters: tableFilters,
              pageCount: data.total_page,
              onRowClick: (row) => history.push(`/${ROUTE.SHIPMENT}/${currentWarehouse}/${row.original.id}`),
            }}
          />
        </div>
      </div>

      {isOpen.createReceivingModal && (
        <CreateReceivingModal
          isOpenReceivingModal={isOpen.createReceivingModal}
          toggleReceivingModal={() => toggleModal('createReceivingModal')}
          receivingStore={receivingStore}
          currentWarehouse={authStore.getWarehouseName(currentWarehouse)}
          isSupportRefurbished={authStore.isSupportRefurbished(currentWarehouse)}
        />
      )}
    </>
  );
};

export default inject('receivingStore', 'authStore')(observer(ConsignmentShipment));

export const ROUTE = {
  INVENTORY: 'inventory',
  SHIPMENT: 'shipment',
  ORDERS: 'orders',
  LOGIN: 'login',
  ERROR: 'error',
  PEPLINKID_CALLBACK: 'peplinkid/callback',
};

export const SHIPMENT_STATUS = {
  ALL: {
    value: '',
    label: 'All',
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
  COMPLETED: {
    value: 'COMPLETED',
    label: 'Completed',
  },
  CLOSING: {
    value: 'CLOSING',
    label: 'Closing',
  },
  CLOSED: {
    value: 'CLOSED',
    label: 'Closed',
  },
  DELIVERED: {
    value: 'DELIVERED',
    label: 'Delivered',
  },
  PENDING_RECEIVE: {
    value: 'PENDING_RECEIVE',
    label: 'Pending Receive',
  },
  PENDING_SHIPPED: {
    value: 'PENDING_SHIPPED',
    label: 'Pending Shipment',
  },
  PENDING_SHIPMENT: {
    value: 'PENDING_SHIPMENT',
    label: 'Pending Shipment',
  },
  PARTIAL_RECEIVED: {
    value: 'PARTIAL_RECEIVED',
    label: 'Partial Received',
  },
  PARTIAL_SHIPPED: {
    value: 'PARTIAL_SHIPPED',
    label: 'Partial Shipped',
  },
  PENDING_CANCEL: {
    value: 'PENDING_CANCEL',
    label: 'Pending Cancel',
  },
  PROCESSING: {
    value: 'PROCESSING',
    label: 'Processing',
  },
  RECEIVED: {
    value: 'RECEIVED',
    label: 'Received',
  },
  SHIPMENT_TO_RECEIVE: {
    value: 'SHIPMENT_TO_RECEIVE',
    label: 'Shipment to Receive',
  },
  SHIPMENT_TO_FULFIL: {
    value: 'SHIPMENT_TO_FULFIL',
    label: 'Shipment To Fulfil',
  },
  SHIPPED: {
    value: 'COMPLETED',
    label: 'Completed',
  },
  WORK_IN_PROGRESS: {
    value: 'WORK_IN_PROGRESS',
    label: 'Work In Progress',
  },
};

export const WAREHOUSE_TYPE = {
  REGULAR: {
    value: 'REGULAR',
    name: 'Regular',
  },
  REFURBISHED: {
    value: 'REFURBISHED',
    name: 'Refurbished',
  },
};

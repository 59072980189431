import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useForm } from 'react-hook-form';
import React, { useState } from 'react';

const CloseReceivingModal = ({ title, onToggle, onSubmit }) => {
  const { register, handleSubmit, errors } = useForm();
  const [confirmModal, setConfirmModal] = useState(false);

  const handleFormSubmit = (data) => {
    onSubmit(data);
    onToggle();
  };

  const handleConfirm = () => {
    setConfirmModal(true);
  };

  const handleConfirmSubmit = () => {
    handleSubmit(handleFormSubmit)();
    setConfirmModal(false);
  };

  return (
    <>
      <Modal isOpen toggle={onToggle}>
        <ModalHeader toggle={onToggle}>{title}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleConfirm();
          }}
        >
          <ModalBody>
            <FormGroup>
              <Label for="closeReason">
                <b>Close Reason</b>
              </Label>
              <Input
                type="textarea"
                name="closeReason"
                id="closeReason"
                innerRef={register({ required: true })}
                invalid={!!errors.closeReason}
              />
              {errors.closeReason && <div className="invalid-feedback">This field is required</div>}
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="dark" onClick={onToggle}>
              Cancel
            </Button>
            <Button color="danger" type="submit">
              Close Receipt
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal isOpen={confirmModal} toggle={() => setConfirmModal(false)}>
        <ModalHeader toggle={() => setConfirmModal(false)}>Confirm</ModalHeader>
        <ModalBody>Are you sure to close this receiving?</ModalBody>
        <ModalFooter>
          <Button color="dark" onClick={() => setConfirmModal(false)}>
            No
          </Button>
          <Button color="danger" onClick={handleConfirmSubmit}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CloseReceivingModal;
